import {createApp} from 'vue'
import App from './App.vue'
import Login from './Login.vue'
import axios from 'ts-axios-new'
import {createRouter, createWebHashHistory} from 'vue-router';
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import {update} from './libs/utils'
import {getLocalObj, setLocalObj, cleanLocalObj} from "./libs/storage";
import {routes} from './routes';

const router = createRouter({
    history: createWebHashHistory(),
    routes
});


let login: any = null;
declare var google: any;

export const root_props = {
    // user info
    user: {
        user_id: '',
        avatar: '',
        name: '',
        email: '',
    },
    user_list: [],
    authorization: '',
};

function init() {
    const app = createApp(App);
    app.use(router);
    app.use(ElementPlus, {locale: zhCn});
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component)
    }
    const vm = app.mount('#app');
    root_props.authorization = getLocalObj('authorization', '');
    update(vm, root_props);
}

window.onload = function () {
    const authorization = getLocalObj('authorization', null);
    axios.get('/cms/v1/me', {headers: {authorization}}).then(res => {
        update(root_props.user, res.data.data);
        axios.interceptors.request.use(function (config) {
            config.headers['Authorization'] = authorization;
            return config
        }, function (error) {
            cleanLocalObj('authorization');
            return Promise.reject(error);
        })
        init();
    }).catch(_ => {
        google.accounts.id.initialize({
            client_id: import.meta.env.VITE_OAUTH2_CLIENT_ID,
            callback: (response: any) => {
                axios.post('/cms/v1/oauth2/login', {
                    platform: 'google',
                    id_token: response.credential
                }).then(res => {
                    update(root_props.user, res.data.data);
                    setLocalObj('authorization', res.data.data.access_token);
                    axios.interceptors.request.use(function (config) {
                        config.headers['Authorization'] = res.data.data.access_token;
                        return config
                    }, function (error) {
                        return Promise.reject(error);
                    })
                    if (login) {
                        login.unmount();
                    }
                    init();
                }).catch(_ => {
                    google.accounts.id.prompt((notification: any) => {
                        if (notification.isDisplayMoment() && notification.isNotDisplayed()) {
                            login = createApp(Login);
                            login.mount('#login');
                        }
                    });
                })
            },
            auto_select: true,
        });
        google.accounts.id.prompt((notification: any) => {
            if (notification.isDisplayMoment() && notification.isNotDisplayed()) {
                login = createApp(Login);
                login.mount('#login');
            }
        });
    })
}
