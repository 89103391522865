import Index from './components/Index.vue';
import Category from './components/library/Category.vue';
import Update from './components/library/Update.vue';
import Rookie from './components/library/Rookie.vue';
import RookieStat from './components/library/RookieStat.vue';
import Carousel from './components/library/Carousel.vue';
import LibraryView from './components/library/View.vue';
import Daily from './components/discovery/Daily.vue';
import Score from './components/Score.vue';
import Metric from './components/Metric.vue';
import Collection from "./components/discovery/Collection.vue";
import CollectionEvent from './components/discovery/CollectionEvent.vue';
import Jigsaw from './components/discovery/Jigsaw.vue';
import DiscoveryView from './components/discovery/View.vue';
import Chosen from './components/library/Chosen.vue';
import Scene from './components/homeland/Scene.vue';
import Config from './components/Config.vue';
import CreateGallery from './components/create/Gallery.vue';
import Bonus from './components/library/Bonus.vue';
import PartTime from './components/PartTime.vue';
import Popup from "./components/Popup.vue";
import Subscription from "./components/vip/Subscription.vue";
import NewbieSales from "./components/vip/NewbieSales.vue";
import Item from "./components/homeland/Item.vue";
import ItemCategory from "./components/homeland/ItemCategory.vue";
import Site from "./components/homeland/Site.vue";
import Scene2 from "./components/homeland/Scene2.vue";

export const routes = [
    {
        name: 'Index',
        path: '/',
        component: Index,
    }, {
        name: 'Category',
        path: '/category',
        component: Category,
    }, {
        name: 'Daily',
        path: '/daily',
        component: Daily,
    }, {
        name: 'Update',
        path: '/update',
        component: Update,
    }, {
        name: 'Rookie',
        path: '/rookie',
        component: Rookie,
    }, {
        name: 'RookieStat',
        path: '/rookie/stat',
        component: RookieStat,
    }, {
        name: 'LibraryView',
        path: '/library',
        component: LibraryView,
    }, {
        name: 'Carousel',
        path: '/carousel',
        component: Carousel,
    }, {
        name: 'Score',
        path: '/score',
        component: Score,
    }, {
        name: 'Metric',
        path: '/metric',
        component: Metric,
    }, {
        name: 'Collection',
        path: '/collection',
        component: Collection,
    }, {
        name: 'Jigsaw',
        path: '/jigsaw',
        component: Jigsaw,
    }, {
        name: 'DiscoveryView',
        path: '/discovery',
        component: DiscoveryView,
    }, {
        name: 'Chosen',
        path: '/chosen',
        component: Chosen,
    }, {
        name: 'CollectionEvent',
        path: '/collection-event',
        component: CollectionEvent,
    }, {
        name: 'Config',
        path: '/config',
        component: Config,
    }, {
        name: 'CreateGallery',
        path: '/create/gallery',
        component: CreateGallery,
    }, {
        name: 'Bonus',
        path: '/bonus',
        component: Bonus,
    }, {
        name: 'PartTime',
        path: '/part-time',
        component: PartTime,
    }, {
        name: 'Popup',
        path: '/popup',
        component: Popup
    }, {
        name: 'Subscription',
        path: '/subscription',
        component: Subscription,
    }, {
        name: 'NewbieSales',
        path: '/newbie-sales',
        component: NewbieSales,
    }, {
        name: 'Item',
        path: '/scape/item',
        component: Item,
    }, {
        name: 'ItemCategory',
        path: '/scape/category',
        component: ItemCategory,
    }, {
        name: 'Site',
        path: '/scape/site',
        component: Site,
    }, {
        name: 'Scene',
        path: '/scape/scene',
        component: Scene,
    }, {
        name: 'Scene2',
        path: '/scape/scene2',
        component: Scene2,
    },
]