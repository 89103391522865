<template>
    <div>
        <el-button type="primary" :loading="loading" @click="open()">新建</el-button>
    </div>
    <el-table :data="data" v-loading="loading" style="margin-top: 10px">
        <el-table-column label="名称" prop="i18n_name.EN" width="150"></el-table-column>
        <el-table-column label="活跃" prop="active" width="150">
            <template #default="scope">
                <el-switch v-model="scope.row.active" :disabled="loading" :inactive-value="false"
                           :active-value="true" @change="update(scope.row)"></el-switch>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
            <template #default="scope">
                <el-button type="success" circle icon="Top" :loading="loading"
                           :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                <el-button type="success" circle icon="Bottom" :loading="loading"
                           :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>
                <el-button :disabled="loading" @click="open(scope.row)">编辑</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" ref="form" label-width="100px">
          <el-form-item :label="`${k}名称`" v-for="(_, k) in form.i18n_name" :prop="`i18n_name.${k}`"
                        :rules="k==='EN'?[{required: true, message: `请输入${k}名称`}]:null">
            <el-input v-model="form.i18n_name[k]" :disabled="loading"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
            <el-button :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from 'ts-axios-new';
import {update} from "../../libs/utils";
import {initI18n_required} from "../../libs/init";

export default {
    name: "Category",
    data() {
        return {
            loading: false, data: [], dialog_opened: false, editing: null,
            form: {
                active: false,
                i18n_name: initI18n_required(),
            },
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/scape/category`).then(res => {
                this.data = res.data.data.categoryList;
                this.loading = false;
            });
        },
        reset() {
            this.$refs.form.resetFields();
            this.loading = this.dialog_opened = false;
            this.editing = null;
        },
        open(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            })
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/scape/category/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        });
                    } else {
                        axios.post(`/cms/v1/scape/category`, this.form).then(res => {
                            this.data.push(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.resort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.resort();
        },
        resort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            })
            this.loading = true;
            axios.post(`/cms/v1/scape/sort/category`, {sort}).then(_ => {
                this.loading = false;
            })
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/scape/category/${row.id}`, row).then(res => {
                this.loading = false;
            })
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>