

export function initI18n_required() {
    return {
        EN: '',
        FR: '',
        IT: '',
        ES: '',
        PT: '',
        DE: '',
        ZH_HANS: '',
        ZH_HANT: '',
        KO: '',
        NL: '',
        PL: '',
        RU: '',
        JA: '',
        NO: '',
        MS: '',
        AR: '',
    }
}
