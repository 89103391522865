<template>
    <div>
        <el-button type="primary" :loading="loading" @click="openDialog()">新建</el-button>
    </div>
    <el-table :data="data" v-loading="loading" style="margin-top: 10px">
        <el-table-column label="ID" prop="id" width="150"></el-table-column>
        <el-table-column label="活跃" prop="active" width="100">
            <template #default="scope">
                <el-switch v-model="scope.row.active" :disabled="loading" @change="update(scope.row)"></el-switch>
            </template>
        </el-table-column>
        <el-table-column label="名称" prop="i18n_name.EN" width="100"></el-table-column>
        <el-table-column label="封面" prop="thumbnail" width="200">
            <template #default="scope">
                <el-image lazy :src="`${$root.env.CDN_URL}/${scope.row.thumbnail}`" style="width: 180px"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="新封面" prop="cover" width="200">
            <template #default="scope">
                <el-image lazy :src="`${$root.env.CDN_URL}/${scope.row.cover}`" v-if="scope.row.cover"
                          style="width: 180px"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="背景" prop="map" width="200">
            <template #default="scope">
                <el-image lazy :src="`${$root.env.CDN_URL}/${scope.row.map}`" style="width: 180px"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="排序" width="100">
            <template #default="scope">
                <el-button type="success" circle icon="Top" :loading="loading"
                           :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                <el-button type="success" circle icon="Bottom" :loading="loading"
                           :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
            <template #default="scope">
                <el-button :disabled="loading" @click="openDialog(scope.row)" circle icon="Edit"></el-button>
                <el-button type="success" :disabled="loading" @click="$refs.site.init(scope.row)" circle
                           icon="LocationInformation"></el-button>
                <el-button type="danger" :disabled="loading" @click="remove(scope.row)" circle
                           icon="Delete"></el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" label-width="120px" ref="form">
            <el-form-item :label="`${k}名称`" v-for="(_, k) in form.i18n_name" :prop="`i18n_name.${k}`"
                          :rules="k==='EN'?[{required: true, message: `请输入${k}名称`}]:null">
                <el-input v-model="form.i18n_name[k]" :disabled="loading"></el-input>
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item label="封面" prop="thumbnail" :rules="[{required: true, message: '请上传封面'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleThumbnailSuccess"
                           accept="image/*" :data="{prefix: 'scene'}" :onProgress="handleProgress"
                           :headers="{authorization: $root.authorization}">
                    <img v-if="form.thumbnail" :src="`${$root.env.CDN_URL}/${form.thumbnail}`" style="width: 200px">
                    <el-button v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="新封面" prop="cover" :rules="[{required: true, message: '请上传封面'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleCoverSuccess"
                           accept="image/*" :data="{prefix: 'scene'}" :onProgress="handleProgress">
                    <img v-if="form.cover" :src="`${$root.env.CDN_URL}/${form.cover}`" style="width: 200px">
                    <el-button v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="背景" prop="map" :rules="[{required: true, message: '请上传背景'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleMapSuccess"
                           accept="image/*" :data="{prefix: 'scene'}" :onProgress="handleProgress"
                           :headers="{authorization: $root.authorization}">
                    <img v-if="form.map" :src="`${$root.env.CDN_URL}/${form.map}`" style="width: 200px">
                    <el-button v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
    <site ref="site" :image_list="image_list"></site>
</template>

<script>
import axios from "ts-axios-new";
import {update} from "../../libs/utils";
import {ElMessageBox} from 'element-plus'
import Site from "./Site";

export default {
    name: "Scene",
    components: {Site},
    data() {
        return {
            loading: false, data: [], editing: null, dialog_opened: false, image_list: [],
            form: {
                i18n_name: {
                    EN: '',
                    FR: '',
                    HI: '',
                    IT: '',
                    AR: '',
                    ES: '',
                    PT: '',
                    DE: '',
                    EL: '',
                    ZH_HANS: '',
                    ZH_HANT: '',
                    ID: '',
                    KO: '',
                    TH: '',
                    NL: '',
                    TR: '',
                    PL: '',
                    VI: '',
                    FI: '',
                    RU: '',
                    HU: '',
                    JA: '',
                    NO: '',
                    SV: '',
                    UK: '',
                    MS: '',
                    IW: '',
                    RO: '',
                    TL: '',
                },
                active: false,
                thumbnail: '',
                cover: '',
                map: '',
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/scene`).then(res => {
                this.data = res.data.data.sceneList;
                this.loading = false;
            })
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.loading = this.dialog_opened = false;
            this.editing = null;
            this.form.i18n_name = {
                EN: '',
                FR: '',
                HI: '',
                IT: '',
                AR: '',
                ES: '',
                PT: '',
                DE: '',
                EL: '',
                ZH_HANS: '',
                ZH_HANT: '',
                ID: '',
                KO: '',
                TH: '',
                NL: '',
                TR: '',
                PL: '',
                VI: '',
                FI: '',
                RU: '',
                HU: '',
                JA: '',
                NO: '',
                SV: '',
                UK: '',
                MS: '',
                IW: '',
                RO: '',
                TL: '',
            }
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/scene/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/scene`, this.form).then(res => {
                            this.data.push(res.data.data);
                            this.sort();
                            this.reset();
                        });
                    }
                }
            })
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.sort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.sort();
        },
        sort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            });
            this.loading = true;
            axios.post(`/cms/v1/sort/scene`, {sort}).then(res => {
                this.loading = false;
            });
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/scene/${row.id}`, row).then(res => {
                this.loading = false;
            });
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/scene/${row.id}`).then(_ => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            })
        },
        handleThumbnailSuccess(res) {
            this.loading = false;
            this.form.thumbnail = res.data.name
        },
        handleCoverSuccess(res) {
            this.loading = false;
            this.form.cover = res.data.name
        },
        handleMapSuccess(res) {
            this.loading = false;
            this.form.map = res.data.name
        },
        handleProgress() {
            this.loading = true;
        },
    },
    mounted() {
        this.init();
        axios.get(`/cms/v1/image`).then(res => {
            this.image_list = res.data.data.imageList;
        });
    }
}
</script>

<style scoped>

</style>