<template>
    <el-dialog v-model="opened" width="80%" :before-close="close">
        <div>
            <el-button type="primary" :loading="loading" @click="openDialog()">新建</el-button>
        </div>
        <el-table :data="data" v-loading="loading" style="margin-top: 10px">
            <el-table-column prop="id" width="150" label="ID"></el-table-column>
            <el-table-column prop="x" width="80" label="X坐标"></el-table-column>
            <el-table-column prop="y" width="80" label="Y坐标"></el-table-column>
            <el-table-column prop="w" width="80" label="宽度"></el-table-column>
            <el-table-column prop="h" width="80" label="高度"></el-table-column>
            <el-table-column label="图片">
                <template #default="scope">
                    <vue-draggable-next v-model="scope.row.images" :draggable="false" :animation="300" handle=".handle"
                                        @change="sort(scope.row)" class="gallery">
                        <template v-for="i in scope.row.images">
                            <div class="item handle" style="position: relative;">
                                <el-image style="width: 100px;height: 100px;" lazy
                                          :src="`${$root.env.CDN_URL}/${i.finished}`"></el-image>
                                <el-icon size="20" @click.stop="removeItem(scope.row, i)"
                                         style="position: absolute;color: var(--el-color-danger); top: 0;right: 0;">
                                    <CircleCloseFilled/>
                                </el-icon>
                            </div>
                        </template>
                        <el-card class="item" shadow="never" @click="openDialogItems(scope.row)">
                            <Plus style="color: var(--el-text-color-secondary)"/>
                        </el-card>
                    </vue-draggable-next>
                </template>
            </el-table-column>
            <el-table-column width="200" label="操作">
                <template #default="scope">
                    <el-button type="success" circle icon="Top" :loading="loading"
                               :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                    <el-button type="success" circle icon="Bottom" :loading="loading"
                               :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>
                    <el-button :disabled="loading" @click="openDialog(scope.row)" circle icon="Edit"></el-button>
                    <el-button type="danger" :disabled="loading" @click="remove(scope.row)" circle
                               icon="Delete"></el-button>
                </template>
            </el-table-column>
        </el-table>
        <template #footer>
          <el-button type="primary" @click="close">关闭</el-button>
        </template>
    </el-dialog>

    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form label-width="100px" ref="form" :model="form">
            <el-form-item label="X坐标" prop="x" :rules="[{required: true, message: '请输入数字', type: 'number'}]">
                <el-input-number v-model="form.x" :disabled="loading"></el-input-number>
            </el-form-item>
            <el-form-item label="Y坐标" prop="y" :rules="[{required: true, message: '请输入数字', type: 'number'}]">
                <el-input-number v-model="form.y" :disabled="loading"></el-input-number>
            </el-form-item>
            <el-form-item label="宽度" prop="w" :rules="[{required: true, message: '请输入数字', type: 'number'}]">
                <el-input-number v-model="form.w" :disabled="loading"></el-input-number>
            </el-form-item>
            <el-form-item label="高度" prop="h" :rules="[{required: true, message: '请输入数字', type: 'number'}]">
                <el-input-number v-model="form.h" :disabled="loading"></el-input-number>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="dialog_items_opened" :before-close="resetItems">
      <div>
        <el-switch v-model="this.only_empty_site" :disabled="loading"
                   :inactive-value="false" :active-value="true"></el-switch>
        <span style="margin-left: 10px;">任何场景都无坐标</span>
      </div>
      <el-table :data="dataFilter" v-loading="loading" style="margin-top: 10px">
        <el-table-column prop="id" width="150" label="ID"></el-table-column>
        <el-table-column prop="thumbnail" width="180" label="缩略图">
          <template #default="scope">
            <el-image :src="`${$root.env.CDN_URL}/${scope.row.thumbnail}`"
                      style="width: 100px"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="finished" width="180" label="完成图">
          <template #default="scope">
            <el-image :src="`${$root.env.CDN_URL}/${scope.row.finished}`"
                      style="width: 100px"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="recommended" width="100" label="是否推荐">
          <template #default="scope">
            <el-tag type="danger" size="small" v-if="scope.row.recommended">{{ 'Yes' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="200" label="操作">
          <template #default="scope">
            <el-button type="primary" @click="addToSite(scope.row)" size="small" :disabled="loading || hasSite(scope.row)">
              {{ hasSite(scope.row) ? '已添加' : '添加' }} </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {update} from "../../libs/utils";
import {ElMessageBox} from 'element-plus';
import {VueDraggableNext} from 'vue-draggable-next'

export default {
    name: "Site",
    props: {
        // image_list: Array,
    },
    components: {VueDraggableNext},
    data() {
        return {
            loading: false, scene: null, opened: false, data: [], item_list: [],
            editing: null, dialog_opened: false, only_empty_site: true,
            form: {
                scene_id: '',
                x: null,
                y: null,
                w: null,
                h: null,
            },
            site_editing: null, dialog_items_opened: false,
        }
    },
    methods: {
        init(scene) {
            this.scene = scene;
            this.loading = this.opened = true;
            axios.all([
                axios.get(`/cms/v1/scape/site`, {params: {scene_id: scene.id}}).then(res => {
                    this.data = res.data.data.siteList;
                }),
                axios.get(`/cms/v1/scape/item`, {params: {scene_id: scene.id}}).then(res => {
                    this.item_list = res.data.data.items;
                    this.item_list.forEach(i => {
                        i.site_map = {}
                        i.sites.forEach(s => {
                          i.site_map[s['scene_id']] = s
                        })
                    })
                }),
            ]).then(_ => {
              this.data.forEach(d => {
                    d.images = [];
                    this.item_list.forEach(p => {
                        if (d.id === this.getSiteId(p)) {
                            d.images.push(p);
                        }
                    })
                });
                this.loading = false;
            })
        },
        close() {
            this.loading = this.opened = false;
            this.only_empty_site = true
            this.data = []
            this.item_list = []
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            });
        },
        reset() {
            this.$refs.form.resetFields();
            this.loading = this.dialog_opened = false;
            this.editing = null;
            this.form = {
              scene_id: '',
              x: null,
              y: null,
              w: null,
              h: null,
            }
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/scape/site/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        this.form.scene_id = this.scene.id;
                        axios.post(`/cms/v1/scape/site`, this.form).then(res => {
                            let site = res.data.data
                            site.images = []
                            this.data.push(site);
                            this.sort();
                            this.reset();
                        })
                    }
                }
            })
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/scape/site/${row.id}`).then(_ => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            })
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.sort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.sort();
        },
        sort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            });
            this.loading = true;
            axios.post(`/cms/v1/scape/sort/site`, {sort}).then(res => {
                this.loading = false;
            });
        },
        openDialogItems(site) {
            this.dialog_items_opened = true;
            this.site_editing = site
        },
        resetItems() {
            this.loading = this.dialog_items_opened = false;
            this.site_editing = null;
        },
        hasSite(item) {
            return item.site_map && item.site_map.hasOwnProperty(this.scene.id)
        },
        isSitesEmpty(item) {
          return !item.site_map || Object.keys(item.site_map).length === 0
        },
        getSiteId(item) {
            if(this.hasSite(item)) {
                return item.site_map[this.scene.id].id
            }
        },
        addToSite(item) {
          if(this.site_editing) {
            item.site_map[this.scene.id] = { id: this.site_editing.id}
            // item.site_id = this.site_editing.id
            item.sites.push({id: this.site_editing.id})
            axios.put(`/cms/v1/scape/item/${item.id}`, item).then(_ => {
                if(!this.site_editing.images) {
                  this.site_editing.images = [];
                }
                this.site_editing.images.push(item);
                this.site_editing.images.sort((a, b) => a.sort - b.sort);
            })
          }
        },
        removeItem(site, item) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                // item.site_id = null
                delete item.site_map[this.scene.id];
                const index = item.sites.findIndex(site => site.id === site.id);
                if (index !== -1) {
                  item.sites.splice(index, 1);
                }
                axios.put(`/cms/v1/scape/item/${item.id}`, item).then(_ => {
                    site.images.splice(site.images.indexOf(item), 1);
                    this.loading = false;
                })
            })
        },
        handleProgress() {
            this.loading = true;
        },
    },
    computed: {
        dataFilter() {
            return this.item_list.filter(i => {
                if (this.only_empty_site) {
                    return this.isSitesEmpty(i)
                } else {
                  return !this.hasSite(i)
                }
            })
        }
    }
}
</script>

<style scoped>
.gallery {
    display: flex;
}

.gallery .item {
    margin: 0 5px;
    width: 100px;
    height: 100px;
    cursor: pointer;
}
</style>